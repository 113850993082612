// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api.new-demo1.livnsense.com/',
  baseUrl2: 'https://new-api.livauth.livnsense.com/',
  // cpcbaseUrl: 'https://api.cpchem.ingenero.livnsense.com/cpchem/',
  apiInterval: 600000,

  // lnsURL: 'http://localhost:4200',
  // weldSenseURL: 'http://localhost:4202',
  // jcbURL: "http://localhost:4201",
  energyURL:"http://localhost:4207",
  // balcoURL:"http://localhost:4321",
  // pharmaURL: "http://localhost:4321",

  newBoiler2URL:'http://localhost:2200/',
  lnsURL: 'https://products.livnsense.com/',
  jcbURL: "https://demo4.livnsense.com/",
  weldSenseURL: 'https://demo6-new.livnsense.com/',
  cpchemURL: "https://demo3-new.livnsense.com",
  p66URL: "https://demo2-new.livnsense.com",
  // energyURL:"https://demo7.livnsense.com",
  balcoURL: "https://dev.smelting.livnsense.com",
  boilerURL: "https://dev.boiler.livnsense.com",
  newboilerURL: "https://new-boiler4.0.livnsense.com",
  // esgURL: "http://localhost:4777",
  // pyroURL: "http://localhost:4890",
  pyroURL: "http://pyro4.0-new.livnsense.com",
  esgURL:"https://dev.esgreport1.livnsense.com",
  pharmaURL: "https://new-pharma.livnsense.com/",
  methaneURL: "https://methanetracking.dev-new.livnsense.com/",




  // lnsURL: 'https://demo1.livnsense.com/',
  // jcbURL: "https://demo4.livnsense.com/",
  // weldSenseURL:"https://demo6.livnsense.com/",
  // cpchemURL: "https://demo3.livnsense.com",
  // p66URL: "https://demo2.livnsense.com",
  // // energyURL:"https://demo7.livnsense.com",
  // balcoURL: "https://dev.smelting.livnsense.com",
  // boilerURL: "https://dev.boiler.livnsense.com",
  // // esgURL: "http://localhost:4777",
  // // pyroURL: "http://localhost:4890",
  // pyroURL: "https://pyro4.0.livnsense.com",
  // esgURL:"https://dev.esgreport.livnsense.com",
  // pharmaURL: "https://pharma.livnsense.com",
  // methaneURL: "https://methanetracking.livnsense.com/",


  errorCauses: [
    `we're doing some maintainance and the site will be back soon.`,
    'your license may expired.',
  ],
  ptAbb: {
    CTI: { parameter: 'Control Tightness Index(CTI) (%)', description: null },
    PPI: { parameter: 'PPI', description: null },
    CEI: {
      parameter: 'Control Effectiveness Index (CEI) (%)',
      description: null,
    },
    ONT: { parameter: 'On-Time (ONT) (%)', description: 'APC is Online' },
    MVU: { parameter: 'MV Utilization (MVU) (%)', description: null },
    MVMM: {
      parameter: 'MV Movement Matrix (MVMM) (%)',
      description: `The MV's that moved less frequently are `,
    },
    CAI: { parameter: 'Constant Activity Index (CAI) (%)', description: null },
  },

  gapanalysisunits: ['$/LB', '$/Gal', '$/Bbl'],

  equipments: {
    'BA-101': 'Furnace (BA-101)',
    'BA-102': 'Furnace (BA-102)',
    'BA-103': 'Furnace (BA-103)',
    'BA-104': 'Furnace (BA-104)',
    'BA-105': 'Furnace (BA-105)',
    'BA-106': 'Furnace (BA-106)',
    'BA-107': 'Furnace (BA-107)',
    'BA-108': 'Furnace (BA-108)',
    'BA-109': 'Furnace (BA-109)',
    'BA-110': 'Furnace (BA-110)',
    'BA-111': 'Furnace (BA-111)',
    'BA-112': 'Furnace (BA-112)',
    'BA-113': 'Furnace (BA-113)',
    'BA-117': 'Furnace (BA-117)',
    GF_HFO_LFO:
      'Gasoline Fractionator (DA-101), HFO (DA-102A) and LFO Stipper (DA-102B) (GF_HFO_LFO)',
    'DA-103': 'Quench Tower (DA-103)',
    'DA-201': 'Gasoline Stripper (DA-201)',
    'FA-155': 'Ethane Surge Drum (FA-155)',
    'FA-116': 'Dilution Steam Generation (FA-116)',
    'FA-118': 'E/P Surge Drum (FA-118)',
    'FA-123': 'Propane Surge Drum (FA-123)',
    'DA-104': 'Process Water Stripper (DA-104)',
    'DA-202': 'Condensate Stripper (DA-202)',
    'DA-203': 'Caustic Tower (DA-203)',
    'DA-301': 'DeMethanizer (DA-301)',
    'DC-301': 'Methanator (DC-301)',
    'GB-201': 'Charge Gas Compressor - 1 (GB-201)',
    'GB-202': 'Charge Gas Compressor - 2 (GB-202)',
    'GB-325': 'Pre-Chill, Cold Box and Expander Recompressor (GB-325)',
    'GB-501': 'Propylene Refrigeration Compressor (GB-501)',
    'GB-601': 'Ethylene Refrigeration Compressor (GB-601)',
    ARU: 'Acetylene Recovery Unit (ARU)',
    'DA-401': 'Deethanizer (DA-401)',
    'DA-403': 'C2 Splitter (DA-403)',
    'DA-404': 'Depropanizer (DA-404)',
    'DA-405': 'Debutanizer (DA-405)',
    'DA-406': 'Primary C3 Splitter (DA-406)',
    'DA-409': 'C3 Stripper (DA-409)',
    'DA-480': 'PP Deethanizer (DA-480)',
    'DA-490': 'Secondary C3 Splitter (DA-490)',
    'DC-402': 'MAPD Convertor (DC-402)',
  },
  features: [
    {
      name: 'Dynamic Benchmarking',
      route: 'dynamic-benchmarking',
      src: 'assets/icons/menu/db.png',
      active: 'assets/icons/menu/dbw.png',
    },
    {
      name: 'Equipment Health',
      route: 'equipment-health',
      src: 'assets/icons/menu/eh.png',
      active: 'assets/icons/menu/ehw.png',
    },
    {
      name: 'Instrument Drift',
      route: 'instrument-drift',
      src: 'assets/icons/menu/id.png',
      active: 'assets/icons/menu/idw.png',
    },
    {
      name: 'Deviation Tracker',
      route: 'deviation-tracker',
      src: 'assets/icons/menu/dt.png',
      active: 'assets/icons/menu/dtw.png',
    },
    {
      name: 'Performance Tracker',
      route: 'performance-tracker',
      src: 'assets/icons/menu/pt.png',
      active: 'assets/icons/menu/ptw.png',
    },
  ],
  filters: [
    { name: '1 Hour', days: 0 },
    { name: '1 Day', days: 0 },
    { name: '7 Days', days: 6 },
    { name: '30 Days', days: 29 },
    { name: 'Since Inception', days: 0 },
  ],
  paramConfig: {
    scheduling_interval: [
      { name: '1 Minute', value: 1 },
      { name: '5 Minutes', value: 5 },
      { name: '10 Minutes', value: 10 },
      { name: '60 Minutes', value: 60 },
    ],
    il_scheduling: [
      { name: '3 Hours', value: 3 },
      { name: '6 Hours', value: 6 },
      { name: '9 Hours', value: 9 },
    ],
    start_time_of_inferential_logic: [
      { name: '12 AM', value: 0 },
      { name: '1 AM', value: 1 },
      { name: '2 AM', value: 2 },
      { name: '3 AM', value: 3 },
      { name: '4 AM', value: 4 },
      { name: '5 AM', value: 5 },
      { name: '6 AM', value: 6 },
      { name: '7 AM', value: 7 },
      { name: '8 AM', value: 8 },
      { name: '9 AM', value: 9 },
      { name: '10 AM', value: 10 },
      { name: '11 AM', value: 11 },
      { name: '12 PM', value: 12 },
      { name: '1 PM', value: 13 },
      { name: '2 PM', value: 14 },
      { name: '3 PM', value: 15 },
      { name: '4 PM', value: 16 },
      { name: '5 PM', value: 17 },
      { name: '6 PM', value: 18 },
      { name: '7 PM', value: 19 },
      { name: '8 PM', value: 20 },
      { name: '9 PM', value: 21 },
      { name: '10 PM', value: 22 },
      { name: '11 PM', value: 23 },
    ],
    input_data_avg_interval: [
      { name: '1 Minute', value: 1 },
      { name: '5 Minutes', value: 5 },
      { name: '30 Minutes', value: 30 },
      { name: '60 Minutes', value: 60 },
    ],
    input_data_std_interval: [
      { name: '30 Minutes', value: 30 },
      { name: '60 Minutes', value: 60 },
    ],
    retrain_flag: [
      { name: 'True', value: 1 },
      { name: 'False', value: 0 },
    ],
    retrain_input_data_avg_interval: [
      { name: '30 Minutes', value: 30 },
      { name: '60 Minutes', value: 60 },
    ],
    retrain_algo_schedule_interval: [
      { name: '1 Month', value: 1 },
      { name: '3 Months', value: 3 },
      { name: '6 Months', value: 6 },
    ],
    active_deactive: [
      { name: 'Activated', value: 1 },
      { name: 'Deactivated', value: 0 },
    ],
    monitor_window_1: null,
    monitor_window_2: null,
    comparision_window: null,
    input_2_offset: null,
    input_data_pulling_period: null,
    input_data_pulling_interval: null,
    historical_lookup_period: null,
  },
  alertFilters: [
    { name: 'Red', value: 4 },
    { name: 'Yellow', value: 3 },
    { name: 'Blue', value: 2 },
    { name: 'Green', value: 1 },
  ],
  graphs: [
    { name: 'popup', graph: { a1: ['MultiLineFixedComponent'] } },
    {
      name: 'Furnace',
      graph: {
        db: ['SingleLinePopupComponent'],
        eh: ['EhMultilineChartComponent'],
        id: ['MultiLineChartComponent'],
        ff: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
      },
    },
    {
      name: 'C2 Splitter',
      graph: {
        db: ['SingleLinePopupComponent'],
        eh: [
          'ScatterChartComponent',
          'ScatterChartComponent',
          'ScatterChartComponent',
          'ScatterChartComponent',
          'ScatterChartComponent',
        ],
        id: ['MultiLineChartComponent'],
        dt: ['DeviationTrackerGraphComponent'],
      },
    },
    {
      name: 'Primary C3 Splitter',
      graph: {
        db: ['SingleLinePopupComponent'],
        eh: ['SingleLineChartComponent', 'SingleLineChartComponent'],
        id: ['MultiLineChartComponent'],
        dt: ['DeviationTrackerGraphComponent'],
      },
    },
    {
      name: 'PP Deethanizer',
      graph: {
        db: ['SingleLinePopupComponent'],
        eh: ['SingleLineChartComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Secondary C3 Splitter',
      graph: {
        db: ['SingleLinePopupComponent'],
        eh: ['SingleLineChartComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Charge Gas Compressor - 1',
      graph: {
        db: ['SingleLinePopupComponent'],
        id: ['MultiLineChartComponent'],
        eh: [
          'MultiLineFixedComponent',
          'MultiLineFixedComponent',
          'MultiLineFixedComponent',
          'MultiLineFixedComponent',
          'MultiLineFixedComponent',
        ],
        dt: ['DeviationTrackerGraphComponent'],
      },
    },
    {
      name: 'Propylene Refrigeration Compressor',
      graph: {
        db: ['SingleLinePopupComponent'],
        id: ['MultiLineChartComponent'],
        dt: ['DeviationTrackerGraphComponent'],
      },
    },
    {
      name: 'Charge Gas Compressor - 2',
      graph: {
        db: ['SingleLinePopupComponent'],
        id: ['MultiLineChartComponent'],
        eh: [
          'MultiLineFixedComponent',
          'MultiLineFixedComponent',
          'MultiLineFixedComponent',
          'MultiLineFixedComponent',
        ],
        dt: ['DeviationTrackerGraphComponent'],
      },
    },
    {
      name: 'Ethane Surge Drum',
      graph: {
        db: ['SingleLinePopupComponent'],
        eh: ['MultiLineFixedComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Caustic Tower',
      graph: {
        db: ['SingleLinePopupComponent'],
        eh: ['MultiLineFixedComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Ethylene Refrigeration Compressor',
      graph: {
        db: ['SingleLinePopupComponent'],
        id: ['MultiLineChartComponent'],
        dt: ['DeviationTrackerGraphComponent'],
      },
    },
    {
      name: 'Quench Tower',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Process Water Stripper',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Gasoline Fractionator HFO',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Gasoline Stripper',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Dilution Steam Generation',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'E/P Surge Drum',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Propane Surge Drum',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Condensate Stripper',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'DeMethanizer',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Pre-Chill, Cold Box and Expander Recompressor',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Acetylene Recovery Unit',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Deethanizer',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Debutanizer',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'Depropanizer',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'C3 Stripper',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
    {
      name: 'MAPD Convertor ',
      graph: {
        db: ['SingleLinePopupComponent'],
        dt: ['DeviationTrackerGraphComponent'],
        id: ['MultiLineChartComponent'],
      },
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 *

 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { ParamService } from 'src/app/core/param.service';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-factory-image',
  templateUrl: './factory-image.component.html',
  styleUrls: ['./factory-image.component.scss'],
})
export class FactoryImageComponent implements OnInit {
  cokeYield: any;
  preRecCokeYield: any;
  postRecCokeYield: any;
  strAlgo: any = false;
  isUser = false;
  cokePred: any;
  periodList1 = ['After a Week', 'After a Month', 'After a Quarter'];
  periodList2 = ['Current', 'Weekly'];
  predictionPeriod1 = this.periodList1[0];
  predictionPeriod2 = this.periodList2[0];
  startDate: any;
  endDate: any;
  param: string;
  minTime: any;
  maxTime: any;
  dateChange: boolean;
  avgTitle: string;
  redCoke: any;
  ifData: any = true;
  period: any;

  constructor(private paramService: ParamService) {
    // max min time
    this.paramService.getMaxMinTime().subscribe((res) => {
      this.minTime = res[0].actual.min_timestamp;
      this.maxTime = res[0].actual.max_timestamp;
    });

    this.paramService.actYield.subscribe((res) => {
      console.log('cokeYield',res);
      this.cokeYield = res;
    });

    this.paramService.predYield.subscribe((res) => {
      // console.log(res);
      this.preRecCokeYield = res;
    });

    this.paramService.postYield.subscribe((res) => {
      console.log('Updated post', res);
      this.postRecCokeYield = res;
    });

    this.paramService.sDate.subscribe((res) => {
      // console.log(res);
      this.startDate = res;
    });

    this.paramService.eDate.subscribe((res) => {
      // console.log(res);
      this.endDate = res;
    });

    this.paramService.preYieldDropdown.subscribe((res) => {
      // console.log(res);
      this.predictionPeriod1 = this.periodList1[res];
    });

    this.paramService.postYieldDropdown.subscribe((res) => {
      // console.log(res);
      this.predictionPeriod2 = this.periodList2[res];
    });

    this.paramService.avg.subscribe((res) => {
      this.avgTitle = res;
    });

    this.paramService.checkVal.subscribe((res) => {
      this.ifData = res;
    });
  }

  ngOnInit(): void {
    let token = localStorage.getItem('user');
    let subToken = token.substr(2, token.length - 3);
    let decodeToken = jwt_decode(subToken);
    if (decodeToken['first_name'] == 'engineer') {
      this.isUser = true;
    } else {
      this.isUser = false;
    }

    this.paramService.sDate.subscribe((res) => {
      this.startDate = res;
      console.log("Start date set: ", this.startDate);
  
      // Check if endDate is already set and then call the API
      if (this.endDate) {
        console.log("Both startDate and endDate are set, calling getPreCokeYield");
        this.getPreCokeYield(this.predictionPeriod1);
      }
    });
  
    this.paramService.eDate.subscribe((res) => {
      this.endDate = res;
      console.log("End date set: ", this.endDate);
  
      // Check if startDate is already set and then call the API
      if (this.startDate) {
        console.log("Both startDate and endDate are set, calling getPreCokeYield");
        this.getPreCokeYield(this.predictionPeriod1);
      }
    });
  
    // Manual call for testing (in case values are not properly retrieved)
    if (this.startDate && this.endDate) {
      console.log("Manually triggering API call in ngOnInit");
      this.getPreCokeYield(this.predictionPeriod1);
    }

    this.paramService.getCokeYieldData().subscribe((res) => {
      this.cokeYield = res[0].coke_yields;
      // this.preRecCokeYield = res[0].weekly_pre_recommended_coke_yields;
      this.postRecCokeYield = res[0].current_post_recommended_coke_yields;
      // console.log(res);
    });

    this.paramService.data.subscribe((data) => {
      this.strAlgo = data;
    });

    // this.paramService.getYieldsPieChartData().subscribe((res) => {
    //   this.cokePred = res[0].predicted_yields_data[6].parameter_value;
    // });

    this.changePeriod1();
    this.changePeriod2();
  }

  getPreCokeYield(period) {
    this.paramService
      .getCokePrePredictedYieldData(this.startDate, this.endDate, period)
      .subscribe((res) => {
        // console.log('preCoke', res[0].current_pre_recommended_coke_yields);
        this.preRecCokeYield = res[0].current_pre_recommended_coke_yields;
        this.cokeYield = res[0].coke_yields;
        this.postRecCokeYield = res[0].current_post_recommended_coke_yields;
        console.log('Pred', this.postRecCokeYield);
      });
  }

  getPostDailyCokeYield() {
    this.paramService
      .getCokePostPredictedYieldData(this.startDate, this.endDate, '')
      .subscribe((res) => {
        // console.log(
        //   'postCoke' + res[0].post_recommended_coke_yields[1].parameter_value
        // );
        this.postRecCokeYield = res[0].current_post_recommended_coke_yields;
        console.log('Post', this.postRecCokeYield);
      });
  }

  getPostWeekCokeYield(period) {
    this.paramService
      .getCokePostPredictedYieldData(this.startDate, this.endDate, period)
      .subscribe((res) => {
        // console.log(
        //   'postCoke' + res[0].post_recommended_coke_yields[1].parameter_value
        // );
        this.postRecCokeYield = res[0].current_post_recommended_coke_yields;
        console.log('Post', this.postRecCokeYield);
      });
  }

  changePeriod1() {
    setTimeout(() => {
      if (this.predictionPeriod1 === 'After a Week') {
        this.getPreCokeYield('Weekly');
      } else if (this.predictionPeriod1 === 'After a Month') {
        this.getPreCokeYield('Monthly');
      } else if (this.predictionPeriod1 === 'After a Quarter') {
        this.getPreCokeYield('Quarterly');
      }
      // this.isLoadingComplete = true;
    }, 1);
    setTimeout(() => {
      if (this.predictionPeriod2 === 'Current') {
        this.getPostDailyCokeYield();
      } else if (this.predictionPeriod2 === 'Weekly') {
        this.getPostWeekCokeYield('Weekly');
      }
      // this.isLoadingComplete = true;
    }, 1);
  }

  changePeriod2() {
    setTimeout(() => {
      if (this.predictionPeriod2 === 'Current') {
        this.getPostDailyCokeYield();
      } else if (this.predictionPeriod2 === 'Weekly') {
        this.getPostWeekCokeYield('Weekly');
      }
      // this.isLoadingComplete = true;
    }, 1);
  }
}

import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
const baseUrl = environment['baseUrl'];
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../pages/setting-configuaration/components/snackbar/snackbar.component';
import { MsgBoxComponent } from '../components/msg-box/msg-box.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ParamService {
  //private baseUrl = 'https://api.frl.livnsense.com';
  private frlbaseUrl = 'https://api-furnance.livnsense.com/frl';
  data = new Subject();

  public SelectedDomain = new BehaviorSubject(1);
  public preYieldDropdown = new Subject<number>();
  public postYieldDropdown = new Subject<number>();
  public actYield = new Subject();
  public predYield = new Subject();
  public postYield = new Subject();
  public sDate = new BehaviorSubject('1/1/2018');
  public avg = new BehaviorSubject('Avg. ');
  public eDate = new BehaviorSubject('6/29/2018');
  public hidePred = new Subject<boolean>();
  public checkVal = new Subject<boolean>();
  public graphPopupTopPosition: number = 5;
  public otherYieldColor = '#CFD2CD';
  public selectedVersion = new BehaviorSubject(1);
  public selectedParameter = new BehaviorSubject('input Feed');
  // public selectedActualParameter = new BehaviorSubject('');
  public selectedYieldParameter = new BehaviorSubject('');
  public inputFeedActData = new BehaviorSubject({});
  public inputFeedPreData = new BehaviorSubject({});
  public cokeDrumActData = new BehaviorSubject({});
  public cokeDrumPreData = new BehaviorSubject({});
  public heaterActData = new BehaviorSubject({});
  public heaterPreData = new BehaviorSubject({});
  public fractActData = new BehaviorSubject({});
  public fractPreData = new BehaviorSubject({});
  public yieldsActualValuesData = new BehaviorSubject({});
  public yieldspredictedValuesData = new BehaviorSubject({});
  // FRL
  public selectedRun = new BehaviorSubject(6);
  public switch = new BehaviorSubject(true);
  inFdParam = [
    {
      sh_name: 'acf',
      parameter: 'ASPHALTENE_CHARGE_TO_FRACTIONATOR',
      value: 'Asphaltene %',
    },
    { sh_name: 'cfv', parameter: 'COKER_FEED_VISCOSITY', value: 'Viscosity' },
    { sh_name: 'ccr', parameter: 'CCR', value: 'CCR %' },
    { sh_name: 'fu', parameter: 'Feed_to_the_unit', value: 'Flow Rate' },
    {
      sh_name: 'ftf',
      parameter: 'FEED_TEMPERATURE_TO_FRACTIONATOR',
      value: 'Temp',
    },
  ];
  cokDrParam = [
    {
      sh_name: 'oodabotc',
      parameter: 'Only_Online_Drum_AB_Overhead_Temp_C',
      value: 'OVHD Temp A/B',
    },
    {
      sh_name: 'oodabop',
      parameter: 'Only_Online_Drum_AB_Overhead_Pressure_KgCm2',
      value: 'OVHD Pressure A/B',
    },
    {
      sh_name: 'oodcdotc',
      parameter: 'Only_Online_Drum_CD_Overhead_Temp_C',
      value: 'OVHD Temp C/D',
    },
    {
      sh_name: 'oodcdop',
      parameter: 'Only_Online_Drum_CD_Overhead_Pressure_KgCm2',
      value: 'OVHD Pressure C/D',
    },
    {
      sh_name: 'daboq',
      parameter: 'Drum_AB_overhead_quench',
      value: 'Quench Temp A/B',
    },
    {
      sh_name: 'dcdoq',
      parameter: 'Drum_CD_overhead_quench',
      value: 'Quench Temp C/D',
    },
    {
      sh_name: 'bbdq',
      parameter: 'Blowdown_bottom_to_drum_quench',
      value: 'Blowdown Temp',
    },
  ];
  heatParam = [
    {
      sh_name: 'ffa',
      parameter: 'FEED_TO_FURNACE_heaterA',
      value: 'Feed Flow Rate A',
    },
    {
      sh_name: 'ffb',
      parameter: 'FEED_TO_FURNACE_heaterB',
      value: 'Feed Flow Rate B',
    },
    {
      sh_name: 'aritha',
      parameter: 'Average_Rad_IL_temp_Heater_A',
      value: 'Radiant Inlet Temp A',
    },
    {
      sh_name: 'acha',
      parameter: 'Average_COT_Heater_A',
      value: 'COT A',
    },
    {
      sh_name: 'tvsha',
      parameter: 'Total_Velocity_Steam_Heater_A',
      value: 'Steam Velocity A',
    },
    {
      sh_name: 'arithb',
      parameter: 'Average_Rad_IL_temp_Heater_B',
      value: 'Radiant Inlet Temp B',
    },
    {
      sh_name: 'achb',
      parameter: 'Average_COT_Heater_B',
      value: 'COT B',
    },
    {
      sh_name: 'tvshb',
      parameter: 'Total_Velocity_Steam_Heater_B',
      value: 'Steam Velocity B',
    },
  ];

  fractParam = [
    {
      sh_name: 'fbt',
      parameter: 'FRACTIONATOR_BOTTOMS_TEMP',
      value: 'Bottom Temp',
    },
    {
      sh_name: 'ftp',
      parameter: 'FRACTIONATOR_TOP_PRESSURE',
      value: 'Top Pressure',
    },
    {
      sh_name: 'ffzt',
      parameter: 'FRACTIONATOR_FLASH_ZONE_TEMPERATURE',
      value: 'Flash Zone Temp',
    },
    {
      sh_name: 'wof_1',
      parameter: 'Wash_oil_1_Flow',
      value: 'Wash Oil Flow Rate 1',
    },
    {
      sh_name: 'wof_2',
      parameter: 'Wash_oil_2_Flow',
      value: 'Wash Oil Flow Rate 2',
    },
    {
      sh_name: 'hdt',
      parameter: 'HCGO_Draw_Temperature',
      value: 'HCGO Draw',
    },
    { sh_name: 'flaring', parameter: 'Flaring', value: 'Flaring Flow Rate' },
  ];

  actYldParam = [
    { sh_name: 'lcn', parameter: 'Total_LCN', value: 'LCN', color: '#fab56b' },
    { sh_name: 'hcn', parameter: 'Total_HCN', value: 'HCN', color: '#F78FF2' },
    {
      sh_name: 'lcgo',
      parameter: 'Total_LCGO',
      value: 'LCGO',
      color: '#A2F96C',
    },
    {
      sh_name: 'hcgo',
      parameter: 'Total_HCGO',
      value: 'HCGO',
      color: '#51f593',
    },
    {
      sh_name: 'lpg',
      parameter: 'Sweet_LPG',
      value: 'Sweet LPG',
      color: '#4eede3',
    },
    {
      sh_name: 'sfbl',
      parameter: 'Sweet_FG_to_Battery_limit',
      value: 'Sweet FG to Battery limit',
      color: '#4288cf',
    },
    {
      sh_name: 'cy',
      parameter: 'coke_yields',
      value: 'Coke',
      color: '#18191a',
    },
  ];

  colorCode = [
    { value: 'LCN', value2: 'Total_LCN', color: '#fab56b' },
    { value: 'HCN', value2: 'Total_HCN', color: '#F78FF2' },
    {
      value: 'LCGO',
      value2: 'Total_LCGO',
      color: '#A2F96C',
    },
    {
      value: 'HCGO',
      value2: 'Total_HCGO',
      color: '#51f593',
    },
    {
      value: 'Sweet_LPG',
      value2: 'Sweet LPG',
      color: '#4eede3',
    },
    {
      value: 'Sweet_FG_to_Battery_limit',
      value2: 'Sweet FG to Battery limit',
      color: '#4288cf',
    },
    {
      value: 'Coke',
      color: '#18191a',
    },
  ];

  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  getDashboardData(startDate, endDate): Observable<any> {
    return this.http
      .get(
        // `${baseUrl}dashboard/actual_avg/data?start_date=2018-01-01T00:00:00.000Z&end_date=2018-06-29T00:00:00.000`
        `${baseUrl}dashboard/actual_avg/data?start_date=${startDate}&end_date=${endDate}`
      )
      .pipe(
        map(
          (response: any) => {
            console.log(response[0]['Fractionator']);

            if (response[0]['Coke Drum'].actual_values == 0) {
              this.checkVal.next(false);
              const dialogRef = this.dialog.open(MsgBoxComponent, {
                disableClose: true,
                hasBackdrop: true,
                closeOnNavigation: true,
                panelClass: 'msg-modal-class',
              });

              dialogRef.afterClosed().subscribe((result) => {
                console.log('The dialog was closed');
              });
            } else {
              this.checkVal.next(true);
            }

            let array1 = response[0]['input Feed']['actual_values'];
            let array2 = response[0]['input Feed']['predicted_values'];
            let array3 = response[0]['Coke Drum']['actual_values'];
            let array4 = response[0]['Coke Drum']['predicted_values'];
            let array5 = response[0]['Heater']['actual_values'];
            let array6 = response[0]['Heater']['predicted_values'];
            let array7 = response[0]['Fractionator']['actual_values'];
            let array8 = response[0]['Fractionator']['predicted_values'];
            let array9 = response[0]['yields']['yields_actual_values'];
            let array10 = response[0]['yields']['yields_predicted_values'];

            const copy1 = [...array1];
            const copy2 = [...array2];
            const copy3 = [...array3];
            const copy4 = [...array4];
            const copy5 = [...array5];
            const copy6 = [...array6];
            const copy7 = [...array7];
            const copy8 = [...array8];
            const copy9 = [...array9];
            const copy10 = [...array10];

            for (let i in this.inFdParam) {
              const targetIndex1 = array1.findIndex(
                (f) => f.sh_name == this.inFdParam[i].sh_name
              );
              if (targetIndex1 >= 0) {
                copy1[targetIndex1]['parameter'] = this.inFdParam[i].value;
              }
              const targetIndex2 = array2.findIndex(
                (f) => f.sh_name == this.inFdParam[i].sh_name
              );
              if (targetIndex2 >= 0) {
                copy2[targetIndex2]['parameter'] = this.inFdParam[i].value;
              }
            }

            for (let i in this.cokDrParam) {
              const targetIndex3 = array3.findIndex(
                (f) => f.sh_name == this.cokDrParam[i].sh_name
              );
              if (targetIndex3 >= 0) {
                copy3[targetIndex3]['parameter'] = this.cokDrParam[i].value;
              }
              const targetIndex4 = array4.findIndex(
                (f) => f.sh_name == this.cokDrParam[i].sh_name
              );
              if (targetIndex4 >= 0) {
                copy4[targetIndex4]['parameter'] = this.cokDrParam[i].value;
              }
            }

            for (let i in this.heatParam) {
              const targetIndex5 = array5.findIndex(
                (f) => f.sh_name == this.heatParam[i].sh_name
              );
              if (targetIndex5 >= 0) {
                copy5[targetIndex5]['parameter'] = this.heatParam[i].value;
              }

              const targetIndex6 = array6.findIndex(
                (f) => f.sh_name == this.heatParam[i].sh_name
              );
              if (targetIndex6 >= 0) {
                copy6[targetIndex6]['parameter'] = this.heatParam[i].value;
              }
            }

            for (let i in this.fractParam) {
              const targetIndex7 = array7.findIndex(
                (f) => f.sh_name == this.fractParam[i].sh_name
              );
              if (targetIndex7 >= 0) {
                copy7[targetIndex7]['parameter'] = this.fractParam[i].value;
              }
              const targetIndex8 = array8.findIndex(
                (f) => f.sh_name == this.fractParam[i].sh_name
              );
              if (targetIndex8 >= 0) {
                copy8[targetIndex8]['parameter'] = this.fractParam[i].value;
              }
            }

            for (let i in this.actYldParam) {
              const targetIndex9 = array9.findIndex(
                (f) => f.sh_name == this.actYldParam[i].sh_name
              );
              if (targetIndex9 >= 0) {
                copy9[targetIndex9]['parameter'] = this.actYldParam[i].value;
              }
            }

            for (let i in this.actYldParam) {
              const targetIndex10 = array10.findIndex(
                (f) => f.sh_name == this.actYldParam[i].sh_name
              );
              if (targetIndex10 >= 0) {
                copy10[targetIndex10]['parameter'] = this.actYldParam[i].value;
              }
            }

            this.inputFeedActData.next(
              response[0]['input Feed']['actual_values']
            );
            this.inputFeedPreData.next(
              response[0]['input Feed']['predicted_values']
            );
            this.cokeDrumActData.next(
              response[0]['Coke Drum']['actual_values']
            );
            this.cokeDrumPreData.next(
              response[0]['Coke Drum']['predicted_values']
            );
            this.heaterActData.next(response[0]['Heater']['actual_values']);
            this.heaterPreData.next(response[0]['Heater']['predicted_values']);
            this.fractActData.next(
              response[0]['Fractionator']['actual_values'].filter(
                (item) =>
                  !(
                    item['parameter'] === 'Flaring Flow Rate' ||
                    item['parameter'] === 'HCGO Draw'
                  )
              )
            );
            this.fractPreData.next(
              response[0]['Fractionator']['predicted_values'].filter(
                (item) =>
                  !(
                    item['parameter'] === 'Flaring Flow Rate' ||
                    item['parameter'] === 'HCGO Draw'
                  )
              )
            );
            this.yieldsActualValuesData.next(
              response[0]['yields']['yields_actual_values']
            );
            this.yieldspredictedValuesData.next(
              response[0]['yields']['yields_predicted_values']
            );
            return response;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  saveDashboardData(data): Observable<any> {
    return this.http.post(`${baseUrl}dashboard/save`, data).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getParaConfiguarationData(number): Observable<any> {
    return this.http
      .get(`${baseUrl}dash_board/over_view?version=${number}`)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  updateParameterValue(value, param): Observable<any> {
    return this.http
      .put(`${baseUrl}configurations/update?parameters=${param}`, value)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  createVersion(value): Observable<any> {
    return this.http.post(`${baseUrl}configurations/add`, value).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getYieldsParameterGraphData(value): Observable<any> {
    let startDate = new Date(
      new Date().setMonth(new Date().getMonth() - 3)
    ).toISOString();
    let endDate = new Date().toISOString();

    if (this.inFdParam.find((i) => i.value == value)) {
      value = this.inFdParam.find((i) => i.value === value).parameter;
    } else if (this.cokDrParam.find((j) => j.value == value)) {
      value = this.cokDrParam.find((j) => j.value === value).parameter;
    } else if (this.heatParam.find((k) => k.value === value)) {
      value = this.heatParam.find((k) => k.value === value).parameter;
    } else if (this.fractParam.find((l) => l.value === value)) {
      value = this.fractParam.find((l) => l.value === value).parameter;
    } else if (this.actYldParam.find((m) => m.value === value)) {
      value = this.actYldParam.find((m) => m.value === value).parameter;
    }

    return this.http
      .get(
        `${baseUrl}dash_board/graph?parameters=${value}&start_date=${startDate}&end_date=${endDate}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getGraphData() {
    let graphData = {
      yields_actual_values: ['190', '180', '170'],
      yields_predicted_values: ['180', '170', '160'],
      x_axis: ['1', '2', '3'],
      unit: '%',
    };
    return graphData;
  }

  getMaxMinTime() {
    return this.http.get(`${baseUrl}dash_board/st_end_date`).pipe(
      map(
        (res: any) => {
          return res;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getCokeYieldData(): Observable<any> {
    return this.http.get(`${baseUrl}dashboard/coke_yields`).pipe(
      map(
        (res: any) => {
          return res;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  avgCokeYieldData(startDate, endDate): Observable<any> {
    return this.http
      .get(
        `${baseUrl}dashboard/avg_coke_yields?start_date=${startDate}&end_date=${endDate}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getYieldsPieChartData(): Observable<any> {
    return this.http.get(`${baseUrl}dash_board/pie_chart`).pipe(
      map(
        (res: any) => {
          return res;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  displayGraphDecimal(value) {
    return Number(value).toFixed(2);
  }

  getCommonActualYieldData(strtDate, endDate): Observable<any> {
    return this.http
      .get(
        `${baseUrl}dash_board/actual_yields/graph?start_date=${strtDate}&end_date=${endDate}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getPredictedYieldData(period): Observable<any> {
    return this.http
      .get(
        `${baseUrl}dash_board/graph?parameters=predicted&end_date=&start_date=&period=${period}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getCokePrePredictedYieldData(strtDate, endDate, period): Observable<any> {
    return this.http
      .get(
        `${baseUrl}dashboard/avg_coke_yields?start_date=${strtDate}&end_date=${endDate}&param=${period}`
        // `${baseUrl}dashboard/pre_post/rec?parameters=pre_rec&period=${period}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getCokePostPredictedYieldData(strtDate, endDate, period): Observable<any> {
    return this.http
      .get(
        `${baseUrl}dashboard/avg_coke_yields?start_date=${strtDate}&end_date=${endDate}&param=${period}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  strtAlgo(req) {
    return this.http.put(`${baseUrl}configurations/status`, req).pipe(
      map(
        (res: any) => {
          return res;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }
  /* Method which opens the snackbar */
  openDuplicateGraphSnackBar() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1500,
      data: { message: 'Graph is already opened' },
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
  //FRL start

  getSingleParamGraphData(param, zone, run) {
    return this.http
      .get(
        `${this.frlbaseUrl}/dash_board/over_view/graph?parameters=${param}&run=${run}&zone=Zone%20${zone}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getPredictedParamGraph(param, zone, run) {
    return this.http
      .get(
        `${this.frlbaseUrl}/dashboard/optimised/graph?parameters=${param}&run=${run}&zone=Zone%20${zone}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getCompareGraph(param, run, zone) {
    return this.http
      .get(
        `${this.frlbaseUrl}/dash_board/over_view/actual_optimised?parameters=${param}&run=${run}&zone=Zone%20${zone}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getMultiParamGraphData(zone, run) {
    return this.http
      .get(
        `${this.frlbaseUrl}/dash_board/multi_line/graph?run=${run}&zone=Zone%20${zone}`
      )
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getAllValues() {
    return this.http.get(`${this.frlbaseUrl}/dashboard/furnace/run/all`).pipe(
      map(
        (res: any) => {
          return res;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getSummaryVal() {
    return this.http.get(`${this.frlbaseUrl}/dashboard/furnace/sor_eor`).pipe(
      map(
        (res: any) => {
          console.log(res);
          return res;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getRunValues(run) {
    return this.http
      .get(`${this.frlbaseUrl}/dashboard/frl_over_view?run=${run}&days=`)
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  runCount() {
    return this.http.get(`${this.frlbaseUrl}/dashboard/run`).pipe(
      map(
        (res: any) => {
          return res;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  furnaceLiveOverview(run) {
    return this.http
      .get(`${this.frlbaseUrl}/dashboard/frl_over_view?run=${run}&days=7`)
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  furnaceOverview(run) {
    return this.http
      .get(`${this.frlbaseUrl}/dashboard/frl_over_view?run=${run}&days=`)
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  furnaceOptimzed(run) {
    return this.http
      .get(`${this.frlbaseUrl}/dashboard/optimised/data?run=${run}`)
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  funrnaceSOR(run1) {
    return this.http
      .get(`${this.frlbaseUrl}/dashboard/furnace/sor_eor?run=${run1}`)
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  // uploadCSV(formData) {
  //   return this.http.post(`${this.baseUrl}/data/upload`, formData, {
  //     reportProgress: true,
  //     observe: 'events',
  //   });
  // }



  actualRunlength(run) {
    return this.http
      .get(`${this.frlbaseUrl}/dashboard/frl_over_view?run=${run}&days=`)
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  getEthaneZonewise(run) {
    return this.http
      .get(`${this.frlbaseUrl}/dashboard/actual_optimise/ethane?run=${run}`)
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  predictedRunlength(run) {
    return this.http
      .get(`${this.frlbaseUrl}/dashboard/optimised/run_length?run=${run}`)
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }
  //FRl End
}

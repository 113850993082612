import { I, S } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit, Component, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const baseUrl = environment['baseUrl'];

@Injectable()
export class UserSharingService {
  [x: string]: any;
  // public demo1Baseurl='https://api.demo1.livnsense.com/'

  public demo1Baseurl='https://api-cognitive.livnsense.com/'

  constructor(private http: HttpClient) {
    console.log('from user service');
  }

  // updateToken(tempertoken:any){
  //   console.log(tempertoken);
  //   const body={
  //     project_code:'demo6',
  //     token: tempertoken,
  //   }
  //  return this.http.post<any>('https://api.demo1.livnsense.com/dcu/algo_token_post?project_id=1&action=I',body)
  // }


  updateToken(body:any) {
    return this.http
      .post<any>(`https://api-cognitive.livnsense.com/dcu/algo_token_post?action=I`, body);
  }

  updateToken1(body:any) {
    return this.http
      .post<any>(`https://api-cognitive.livnsense.com/dcu/algo_token_post?action=I`, body);
  }

  updateToken2(body:any) {
    return this.http
      .post<any>(`https://api-cognitive.livnsense.com/dcu/algo_token_post?action=I`, body);
  }

  updateToken3(body:any) {
    return this.http
      .post<any>(`https://api-cognitive.livnsense.com/dcu/algo_token_post?action=I`, body);
  }

  updateToken4(body:any) {
    return this.http
      .post<any>(`https://api-cognitive.livnsense.com/dcu/algo_token_post?action=I`, body);
  }

  updateToken5(body:any) {
    return this.http
      .post<any>(`https://api-cognitive.livnsense.com/dcu/algo_token_post?action=I`, body);
  }

  updateToken6(body:any) {
    return this.http
      .post<any>(`https://api-cognitive.livnsense.com/dcu/algo_token_post?action=I`, body);
  }












  // updateToken(body:any) {
  //   return this.http
  //     .post<any>(`https://api.demo1.livnsense.com/dcu/algo_token_post?action=I`, body);
  // }

  // updateToken1(body:any) {
  //   return this.http
  //     .post<any>(`https://api.demo1.livnsense.com/dcu/algo_token_post?action=I`, body);
  // }

  // updateToken2(body:any) {
  //   return this.http
  //     .post<any>(`https://api.demo1.livnsense.com/dcu/algo_token_post?action=I`, body);
  // }

  // updateToken3(body:any) {
  //   return this.http
  //     .post<any>(`https://api.demo1.livnsense.com/dcu/algo_token_post?action=I`, body);
  // }

  // updateToken4(body:any) {
  //   return this.http
  //     .post<any>(`https://api.demo1.livnsense.com/dcu/algo_token_post?action=I`, body);
  // }

  // updateToken5(body:any) {
  //   return this.http
  //     .post<any>(`https://api.demo1.livnsense.com/dcu/algo_token_post?action=I`, body);
  // }

  // updateToken6(body:any) {
  //   return this.http
  //     .post<any>(`https://api.demo1.livnsense.com/dcu/algo_token_post?action=I`, body);
  // }
  postCrossDomainMessage(link: any, portal: string) {
    let postURL: any;
    let iframeId: any;
    if (portal === 'jcb') {
      postURL = environment.lnsURL;
      iframeId = 'jcb-ifr';
    } else if (portal === 'weldSense') {
      postURL = environment.lnsURL;
      iframeId = 'weld-ifr';
    }
    else if (portal === 'cpchem') {
      postURL = environment.lnsURL;
      iframeId = 'cpc-ifr';
    }
    else if (portal === 'p66') {
      postURL = environment.lnsURL;
      iframeId = 'p66-ifr';
    }
    else if (portal === 'energysense') {
      postURL = environment.lnsURL;
      iframeId = 'energy-ifr';
    }
    else if (portal === 'balco') {
      postURL = environment.balcoURL;
      iframeId = 'balco-ifr';
    }
    else if (portal === 'boiler') {
      postURL = environment.boilerURL;
      iframeId = 'boiler-ifr';
    }
    const iframe = document.getElementById(iframeId);
    console.log(iframe);
    if (iframe == null) {
      return;
    }
    const iWindow = (iframe as HTMLIFrameElement).contentWindow;
    const storageData = JSON.parse(localStorage.getItem('currentUser'));
    console.log(storageData);
    setTimeout(() => {
      // iWindow.postMessage(storageData, environment.jcbURL);
      iWindow.postMessage(storageData, environment.weldSenseURL);
    }, 5000);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ParamService } from 'src/app/core/param.service';
import { ActualParameterGraphModalComponent } from '../actual-parameter-graph-modal/actual-parameter-graph-modal.component';
import { ActualMainYieldGraphModalComponent } from '../actual-main-yield-graph-modal/actual-main-yield-graph-modal.component';
import { PredictedMainYieldGraphModalComponent } from '../predicted-main-yield-graph-modal/predicted-main-yield-graph-modal.component';
import { Predicted1MainYieldGraphModalComponent } from '../predicted1-main-yield-graph-modal/predicted1-main-yield-graph-modal.component';
import jwt_decode from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-graph',
  templateUrl: './common-graph.component.html',
  styleUrls: ['./common-graph.component.scss'],
})
export class CommonGraphComponent implements OnInit {
  @Input() selection;
  graphData: any[] = [];
  isAlgoStart: boolean;
  strAlgo: any = false;
  isUser = false;
  chartId = 'Flow Rate' + 'ActCri';
  selectedGraphList: any[] = [];
  actualMainYield = ActualMainYieldGraphModalComponent;
  preRecMainYield = Predicted1MainYieldGraphModalComponent;
  postRecMainYield = PredictedMainYieldGraphModalComponent;
  ifData: boolean;

  constructor(
    private paramService: ParamService,
    public datepipe: DatePipe,
    public router: Router,
    public dialog: MatDialog
  ) {
    this.paramService.checkVal.subscribe((res) => {
      console.log(res);
      this.ifData = res;
    });
  }

  ngOnInit(): void {
    // let token = localStorage.getItem('currentUser');
    let token = localStorage.getItem('user');
    let subToken = token.substr(2, token.length - 3);
    let decodeToken = jwt_decode(subToken);
    if (decodeToken['first_name'] == 'engineer') {
      this.isUser = true;
    } else {
      this.isUser = false;
    }

    this.paramService.data.subscribe((data) => {
      this.strAlgo = data;
    });
  }

  openCritical(sh_name) {
    if (this.selectedGraphList.includes(this.chartId)) {
      this.paramService.openDuplicateGraphSnackBar();
    } else {
      this.selectedGraphList.push(this.chartId);
      this.paramService.graphPopupTopPosition += 2;
      const modalRef = this.dialog.open(ActualParameterGraphModalComponent, {
        disableClose: true,
        hasBackdrop: false,
        closeOnNavigation: true,
        panelClass: 'normal-modal-class',
        position: {
          top: this.paramService.graphPopupTopPosition.toString() + '%',
        },
      });
      this.router.events.subscribe(() => {
        modalRef.close();
      });

      this.paramService.selectedYieldParameter.next(
        this.paramService.inFdParam.find((i) => i.sh_name === sh_name).value
      );
      let flowRateList = [
        { value: 'Flow Rate', dis_name: 'Input Feed Flow Rate' },
        { value: 'Feed Flow Rate A', dis_name: 'Heater Flow Rate A' },
        { value: 'Feed Flow Rate B', dis_name: 'Heater Flow Rate B' },
      ];
      let tempList = [
        { value: 'Temp', dis_name: 'Input Feed Temp' },
        { value: 'COT A', dis_name: 'COT A' },
        { value: 'COT B', dis_name: 'COT B' },
        { value: 'Bottom Temp', dis_name: 'Bottom Temp' },
      ];
      let pressureList = [
        { value: 'Top Pressure', dis_name: 'Top Pressure' },
        { value: 'OVHD Pressure A/B', dis_name: 'OVHD Pressure A/B' },
        { value: 'OVHD Pressure C/D', dis_name: 'OVHD Pressure C/D' },
      ];
      let otherList = [{ value: 'CCR %', dis_name: 'CCR %' }];
      let allCriticalParam = [
        { sh_name: 'fu', parameter: 'Feed_to_the_unit', value: 'Flow Rate' },
        {
          sh_name: 'ffa',
          parameter: 'FEED_TO_FURNACE_heaterA',
          value: 'Feed Flow Rate A',
        },
        {
          sh_name: 'ffb',
          parameter: 'FEED_TO_FURNACE_heaterB',
          value: 'Feed Flow Rate B',
        },
        {
          sh_name: 'ftf',
          parameter: 'FEED_TEMPERATURE_TO_FRACTIONATOR',
          value: 'Temp',
        },
        {
          sh_name: 'acha',
          parameter: 'Average_COT_Heater_A',
          value: 'COT A',
        },
        {
          sh_name: 'achb',
          parameter: 'Average_COT_Heater_B',
          value: 'COT B',
        },
        {
          sh_name: 'fbt',
          parameter: 'FRACTIONATOR_BOTTOMS_TEMP',
          value: 'Bottom Temp',
        },
        {
          sh_name: 'ftp',
          parameter: 'FRACTIONATOR_TOP_PRESSURE',
          value: 'Top Pressure',
        },
        {
          sh_name: 'oodabop',
          parameter: 'Only_Online_Drum_AB_Overhead_Pressure_KgCm2',
          value: 'OVHD Pressure A/B',
        },
        {
          sh_name: 'oodcdop',
          parameter: 'Only_Online_Drum_CD_Overhead_Pressure_KgCm2',
          value: 'OVHD Pressure C/D',
        },
        { sh_name: 'ccr', parameter: 'CCR', value: 'CCR %' },
      ];
      // let dropDownList = [];
      // for (let j = 0; j < this.paramService.inFdParam.length; j++) {
      //   if (
      //     this.paramService.inFdParam[j].value === 'Flow Rate' ||
      //     this.paramService.inFdParam[j].value === 'CCR %'
      //   )
      //     dropDownList.push({ parameter: this.paramService.inFdParam[j].value });
      // }
      modalRef.componentInstance.selectedParameter = 'Flow Rate';
      // modalRef.componentInstance.flowRateParam = flowRateList;
      // modalRef.componentInstance.tempParam = tempList;
      // modalRef.componentInstance.pressureParam = pressureList;
      // modalRef.componentInstance.otherParam = otherList;
      modalRef.componentInstance.fromParamTable = allCriticalParam;
      modalRef.componentInstance.isCriticalParameter = true;
      modalRef.componentInstance.chartId = this.chartId;
      modalRef.componentInstance.isNotPredictedLine = true;

      modalRef.afterClosed().subscribe((result) => {
        this.selectedGraphList.splice(
          this.selectedGraphList.indexOf(result),
          1
        );
        this.paramService.graphPopupTopPosition -= 2;
      });
    }
  }

  openMainYield(component, chartId) {
    if (this.selectedGraphList.includes(chartId)) {
      this.paramService.openDuplicateGraphSnackBar();
    } else {
      this.selectedGraphList.push(chartId);
      this.paramService.graphPopupTopPosition += 2;
      const modalRef = this.dialog.open(component, {
        disableClose: true,
        hasBackdrop: false,
        closeOnNavigation: true,
        data: chartId,
        panelClass: 'normal-modal-class',
        position: {
          top: this.paramService.graphPopupTopPosition.toString() + '%',
        },
      });
      this.router.events.subscribe(() => {
        modalRef.close();
      });
      modalRef.afterClosed().subscribe((result) => {
        this.selectedGraphList.splice(
          this.selectedGraphList.indexOf(result),
          1
        );
        this.paramService.graphPopupTopPosition -= 2;
      });
    }
  }
}

import { LoadingBarComponent } from './../loading-bar/loading-bar.component';
import { Component, OnInit } from '@angular/core';
import { ParamService } from 'src/app/core/param.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarComponent } from 'src/app/pages/setting-configuaration/components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dcu-yields',
  templateUrl: './dcu-yields.component.html',
  styleUrls: ['./dcu-yields.component.scss'],
})
export class DcuYieldsComponent implements OnInit {
  selectedParameter: any;
  predictedParameter: { name: string }[];
  today: number = Date.now();
  lastUpdatedDate: any;
  yieldspredictedValuesData: any;
  predictedValuesData: any;
  header: string;
  isAdmin = true;
  predictedValuesData1: any;
  num: any;
  hidePredict = true;
  ifData: any = true;

  startDate = new Date(new Date().setDate(new Date().getDate() - 6)).toISOString();
  endDate = new Date().toISOString();

  dcuParameter = [
    { name: 'Input Feed', value: 'input Feed' },
    { name: 'Heater', value: 'Heater' },
    { name: 'Coke Drum', value: 'Coke Drum' },
    { name: 'Fractionator', value: 'Fractionator' },
  ];

  constructor(
    private paramService: ParamService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router
  ) {
    // let token = localStorage.getItem('currentUser');
    let token = localStorage.getItem('user');
    let subToken = token.substr(2, token.length - 3);
    let decodeToken = jwt_decode(subToken);
    if (decodeToken['role'] === 'operator') {
      this.paramService.selectedParameter.subscribe((res: any) => {
        this.selectedParameter = res;
        this.isAdmin = false;
        if (this.selectedParameter === 'input Feed') {
          this.paramService.inputFeedPreData.subscribe((res: any) => {
            this.predictedValuesData1 = res;
            this.header = 'RECOMMENDED ' + 'INPUT FEED ' + 'PARAMETERS';
          });
        }
      });
      this.paramService.yieldspredictedValuesData.subscribe((res: any) => {
        this.yieldspredictedValuesData = res;
      });
    } else {
      this.isAdmin = true;
    }


    this.paramService.hidePred.subscribe((res) => {
      this.hidePredict = res;
    });

    this.paramService.checkVal.subscribe((res) => {
      console.log(res);
      this.ifData = res;
    });

    this.getDashboardData();
  }

  getDashboardData() {
    this.paramService
      .getDashboardData(this.startDate, this.endDate)
      .subscribe((res) => {});
  }

  ngOnInit(): void {
    this.getLastUpdatedDate(this.num);
  }

  getLastUpdatedDate(num) {
    // this.paramService.getDashboardData(num1).subscribe((res) => {
    //   this.lastUpdatedDate = res[0].timestamp;
    // });
  }

  startAlg() {
    var req;
    this.paramService.strtAlgo(req).subscribe((res) => {
      console.log(res);
    });
    this.dialog.closeAll();
    const modalRef = this.dialog.open(LoadingBarComponent, {
      disableClose: true,
      hasBackdrop: true,
      closeOnNavigation: true,
      panelClass: 'loading-modal-class',
      backdropClass: 'backdropBackground',
    });
    this.router.events.subscribe(() => {
      modalRef.close();
    });
    setTimeout(() => {
      console.log("dcu",true)
      this.paramService.data.next(true);
      this.hidePredict = false;
      modalRef.close();
      this.changeParameter(); //this line
      this.paramService.yieldspredictedValuesData.subscribe((res: any) => {
        this.yieldspredictedValuesData = res;
      });
    }, 7000);
    this.selectedParameter = null;
  }

  changeParameter() {
    this.paramService.selectedParameter.subscribe((res: any) => {
      this.selectedParameter = res;
      if (this.selectedParameter === 'input Feed') {
        this.paramService.inputFeedPreData.subscribe((res: any) => {
          this.predictedValuesData = res;
          this.header = 'RECOMMENDED ' + 'INPUT FEED ' + 'PARAMETERS';
        });
      } else if (this.selectedParameter === 'Coke Drum') {
        this.paramService.cokeDrumPreData.subscribe((res: any) => {
          this.predictedValuesData = res;
          this.header = 'RECOMMENDED ' + 'COKE DRUM ' + 'PARAMETERS';
        });
      } else if (this.selectedParameter === 'Heater') {
        this.paramService.heaterPreData.subscribe((res: any) => {
          this.predictedValuesData = res;
          this.header = 'RECOMMENDED ' + 'HEATER ' + 'PARAMETERS';
        });
      } else if (this.selectedParameter === 'Fractionator') {
        this.paramService.fractPreData.subscribe((res: any) => {
          this.predictedValuesData = res;
          this.header = 'RECOMMENDED ' + 'FRACTIONATOR ' + 'PARAMETERS';
        });
      }
    });
  }

  // saveDashboardData() {
  //   this.paramService.getDashboardData(1).subscribe((get) => {
  //     this.paramService.saveDashboardData(get).subscribe(
  //       (result) => {
  //         this.openSnackBar(result);
  //       },
  //       (err) => {
  //         this.openSnackBar(err['error']);
  //       }
  //     );
  //   });
  // }

  // download dashboard data in pdf
  saveDashboardData() {
    // let num;
    // this.paramService.getDashboardData(num).subscribe((res) => {
    //   console.log(res[0]);
    //   var docDefinition = {
    //     pageOrientation: 'landscape',
    //     content: [
    //       {
    //         // layout: 'lightHorizontalLines', // optional
    //         table: {
    //           // headers are automatically repeated if the table spans over multiple pages
    //           // you can declare how many rows should be treated as headers
    //           headerRows: 1,
    //           widths: ['*', 'auto', 100, '*'],
    //           body: [
    //             ['First', 'Second', 'Third', 'The last one'],
    //             ['Value 1', 'Value 2', 'Value 3', 'Value 4'],
    //             [{ text: 'Bold value', bold: true }, 'Val 2', 'Val 3', 'Val 4'],
    //           ],
    //         },
    //       },
    //     ],
    //   };
    //   // // const documentDefinition = { content: 'This is for testing.' };
    //   pdfMake.createPdf(docDefinition).open();
    // });
  }

  /* Method which opens the snackbar */
  openSnackBar(res) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1500,
      data: res,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  changePredictedParameter() {
    if (this.selectedParameter === 'input Feed') {
      this.paramService.inputFeedPreData.subscribe((res: any) => {
        this.predictedValuesData1 = res;
        this.header = 'RECOMMENDED ' + 'INPUT FEED ' + 'PARAMETERS';
      });
    } else if (this.selectedParameter === 'Coke Drum') {
      this.paramService.cokeDrumPreData.subscribe((res: any) => {
        this.predictedValuesData1 = res;
        this.header = 'RECOMMENDED ' + 'COKE DRUM ' + 'PARAMETERS';
      });
    } else if (this.selectedParameter === 'Heater') {
      this.paramService.heaterPreData.subscribe((res: any) => {
        this.predictedValuesData1 = res;
        this.header = 'RECOMMENDED ' + 'HEATER ' + 'PARAMETERS';
      });
    } else if (this.selectedParameter === 'Fractionator') {
      this.paramService.fractPreData.subscribe((res: any) => {
        this.predictedValuesData1 = res;
        this.header = 'RECOMMENDED ' + 'FRACTIONATOR ' + 'PARAMETERS';
      });
    }
  }
}
